/*------------------------------------*\
  #Fonts
\*------------------------------------*/

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url("./fonts/Open_Sans/opensans-light.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: url("./fonts/Open_Sans/opensans-lightitalic.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url("./fonts/Open_Sans/opensans-regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: url("./fonts/Open_Sans/opensans-italic.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url("./fonts/Open_Sans/opensans-semibold.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 600;
    src: url("./fonts/Open_Sans/opensans-semibolditalic.ttf") format('truetype');
}

@font-face {
    font-family: 'Caveat';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url("./fonts/Caveat/static/caveat-regular.ttf") format('truetype');
}

@font-face {
    font-family: 'icomoon';
    src:  url('./fonts/Asam_Icons/icomoon.eot');
    src:  url('./fonts/Asam_Icons/icomoon.eot#iefix') format('embedded-opentype'),
    url('./fonts/Asam_Icons/icomoon.ttf') format('truetype'),
    url('./fonts/Asam_Icons/icomoon.woff') format('woff'),
    url('./fonts/Asam_Icons/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}